import { render, staticRenderFns } from "./CourseOverviews.vue?vue&type=template&id=26edbf7c&scoped=true&"
import script from "./CourseOverviews.vue?vue&type=script&lang=js&"
export * from "./CourseOverviews.vue?vue&type=script&lang=js&"
import style0 from "./CourseOverviews.vue?vue&type=style&index=0&id=26edbf7c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26edbf7c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VAutocomplete,VCol,VDataTable,VPagination,VRow,VSelect})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentRole === 4 || _vm.currentRole === 5)?_c('div',{staticClass:"mt-3 ml-10 mr-10",attrs:{"id":"course-management"}},[_c('v-row',{attrs:{"md":"12","no-gutters":""}},[_c('v-col',{attrs:{"sm":"2"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h2',[_vm._v("Course Overview")])])])],1),_c('v-row',[_c('v-col',{staticClass:"mb-5",attrs:{"sm":"2"}},[_c('v-select',{attrs:{"items":_vm.centerList,"item-text":"location","item-value":"locationID","append-icon":"keyboard_arrow_down","outlined":"","dense":"","hide-details":"","placeholder":"Center"},on:{"change":_vm.reset},model:{value:(_vm.locationID),callback:function ($$v) {_vm.locationID=$$v},expression:"locationID"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.classStatus,"label":"Class status","item-text":"classStatus","item-value":"classStatusID","outlined":"","dense":""},on:{"change":_vm.reset},model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}})],1)],1),[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pl-0",attrs:{"md":"12"}},[_c('div',{staticClass:"pl-0 pr-0 pb-10 pt-0"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.filtered,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-header":"","hide-default-footer":"","id":"revenueTable","fixed-header":"","height":"70vh"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text,staticClass:"text-start font-weight-bold",style:('width:' +
                      header.width +
                      '; min-width:' +
                      header.width +
                      ';' +
                      ' background-color:' +
                      _vm.getColor(header.text) +
                      ';' +
                      'color:' +
                      '#0C2E68 !important'),attrs:{"role":"columnheader","scope":"col","aria-label":"No"}},[_c('span',[_vm._v(_vm._s(header.text))])])}),0)]),_c('tr',{staticClass:"fixed-row-filter"},_vm._l((props.headers),function(header){return _c('th',{key:header.text},[(_vm.filters.hasOwnProperty(header.value))?_c('div',[(_vm.filters.hasOwnProperty(header.value))?_c('Autocomplete',{attrs:{"styleBorder":'border-bottom',"keyFilter":header.value,"selectedInit":_vm.filters[header.value],"listItem":_vm.groupColumnValueList(header.value)},on:{"setFilter":_vm.setFilter}}):_vm._e()],1):_vm._e()])}),0)]}},{key:"item.classCode",fn:function(ref){
                      var item = ref.item;
return [_c('td',{staticClass:"sticky-left link",style:({
                  position: 'sticky',
                  left: '0',
                  cursor: 'pointer',

                  color: '#427df2',
                }),on:{"click":function($event){return _vm.viewDetailClass(item.classID)}}},[_c('p',[_vm._v(_vm._s(item.classCode))])])]}},{key:"item.payableFee",fn:function(ref){
                var item = ref.item;
return [_c('td',{staticClass:"sticky-left"},[_c('p',[_vm._v(_vm._s(_vm.numberWithCommas(item.payableFee)))])])]}},{key:"item.schoolFee",fn:function(ref){
                var item = ref.item;
return [_c('td',{staticClass:"sticky-left"},[_c('p',[_vm._v(_vm._s(_vm.numberWithCommas(item.schoolFee)))])])]}}],null,true)})],1)])],1)],_c('v-row',[_c('v-col',{attrs:{"md":"2"}},[_c('v-select',{staticStyle:{"height":"32px"},attrs:{"outlined":"","hide-details":"","dense":"","value":_vm.itemsPerPage,"items":[20, 50, 100, 200]},on:{"change":function($event){_vm.itemsPerPage = parseInt($event, 10)}}})],1),_c('v-col',{staticClass:"d-flex align-center justify-end",attrs:{"md":"4"}},[_vm._v(" Total : "+_vm._s(_vm.filtered.length))]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"md":"6"}},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"elevation":"0","total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }